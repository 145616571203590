import React, { Component } from 'react'

import './SponsorExplorer.css'

import AltiumLogo from './../../../img/sponsor-logos/altium-logo.svg';
import HaasLogo from './../../../img/sponsor-logos/haas-logo.svg';
import MacEngLogo from './../../../img/sponsor-logos/mac-eng-logo.png';
import MESLogo from './../../../img/sponsor-logos/mes-logo.svg';
import PCBLibrariesLogo from './../../../img/sponsor-logos/pcblibraries-logo.png';
import SolidworksLogo from './../../../img/sponsor-logos/solidworks-logo.svg';
// import YourLogoHere from './../../../img/sponsor-logos/your-logo-here.png';
import KvaserLogo from './../../../img/sponsor-logos/kvaser-logo.png';
import PetroCanadaLogo from '../../../img/sponsor-logos/Petro_canada_logo.svg';
import DanaLogo from '../../../img/sponsor-logos/dana-logo.png';
import KISSoftLogo from '../../../img/sponsor-logos/kissoft-logo.jpg';
import TeslaLogo from '../../../img/sponsor-logos/tesla-logo.png';
import MTEConsultantsLogo from '../../../img/sponsor-logos/mte-consultants-logo.jpg';



export class SponsorExplorer extends Component {

  constructor (props) {
    super(props)
    this.state = {
        lubrication: [
            {id: 1,
                name: 'Petro-Canada Lubricants',
                websiteLink: 'https://lubricants.petro-canada.com/',
                img: PetroCanadaLogo,
                description:"For over 30 years, under our Petro-Canada Lubricants brand, we have researched, developed and produced world-class advanced lubricants, specialty fluids and greases. Our products continue to perform beyond expectations in virtually every industry around the globe. ",
                color:"rgb(0,0,0)"}
        ],
      gold: [
          {id: 1,
              name: 'Gene Haas Foundation',
              websiteLink: 'https://ghaasfoundation.org/',
              img: HaasLogo,
              description:"Established by Gene Haas in 1999, the Gene Haas Foundation supports various programs to support local community needs. They also support manufacturing training programs in order to stimulate growth and quality of students who will join the workforce as skilled employees.",
              color:"rgb(208, 32, 47)"},
          {id: 2,
              name: 'Faculty of Engineering, McMaster University',
              websiteLink: 'https://www.eng.mcmaster.ca/',
              img: MacEngLogo,
              description:"The McMaster Faculty of Engineering is known for innovative educational programming and for its research and engagement with industry and community. Focusing on Problem-Based Learning, the Faculty builds strong foundations for undergraduate and graduate students in their professional careers.",
              color:"rgb(253, 191, 87)"},
          {id: 3,
              name: 'McMaster Engineering Society',
              websiteLink: 'https://www.macengsociety.ca/',
              img: MESLogo,
              description:"The McMaster Engineering Society fosters the development of well rounded undergraduate engineering students through accessible support of academics, athletics, and recreational and professional activities, while maintaining the integrity and unique traditions of the faculty society.",
              color:"rgb(167, 29, 48)"},
          {id: 4,
              name: 'KISSoft',
              websiteLink: 'https://www.kisssoft.com/en',
              img: KISSoftLogo,
              description:"Our software includes internationally recognized calculation standards as well as various design and optimization options, based on the experience of our customers and development engineers - according to our slogan \"Sharing Knowledge\".",
              color:"rgb(16, 117, 189)"},
          {id: 5,
              name: 'Dana',
              websiteLink: 'https://www.dana.com/',
              img: DanaLogo,
              description:"Dana is a leader in the design and manufacture of highly efficient propulsion and energy-management solutions that power vehicles and machines in all mobility markets across the globe.",
              color: "rgb(28, 133, 199)"},
          {id: 6,
              name: "Tesla",
              websiteLink: 'https://www.tesla.com/en_ca',
              img: TeslaLogo,
              description: "We’re building a world powered by solar energy, running on batteries and transported by electric vehicles. Explore the most recent impact of our products, people and supply chain.",
              color: "rgb(0,0,0)"}
      ],
      silver: [
          {id: 1,
              name: 'Altium',
              websiteLink: 'https://education.altium.com/',
              img: AltiumLogo,
              description:"Industry leading PCB design software that empowers users to design boards without limits—from single PCB to multi-board systems; from low frequency to high-speed.",
              color:"rgb(166,151,106)"},
          {id: 2,
              name: 'Solidworks',
              websiteLink: 'https://www.solidworks.com/',
              img: SolidworksLogo,
              description:"SOLIDWORKS’ is a solid modelling computer-aided design (CAD) and computer-aided engineering (CAE) computer program. The program used by industry leaders provides integrated tools for every area of product development and management. Enabling enterprises to optimize processes from concepts to manufacturing.",
              color:"rgb(209,73,67)"},
          {id: 3,
              name: 'PCB Libraries',
              websiteLink: 'https://www.pcblibraries.com/',
              img: PCBLibrariesLogo,
              description:"PCB Libraries is a lean and agile software company that offers unparalleled software for accurate, and amazingly efficient PCB library creation and management. Their goal is to offer the highest quality and most affordable PCB design solutions available, at the lowest cost possible. They have been actively involved in development of the IPC-7351 for many years.",
              color:"rgb(0,102,179)"},
          {id: 4,
              name: "MTE Consultants",
              websiteLink: 'https://www.mte85.com/',
              img: MTEConsultantsLogo,
              description: "MTE Consultants is a multidisciplinary engineering firm providing services in civil and structural engineering, building and environmental science, toxicology and land surveying.",
              color: "rgb(0, 105, 144)"},
      ],
      bronze: [
        {id: 1,
            name: 'Kvaser',
            websiteLink: 'http://www.kvaser.com/',
            img: KvaserLogo}
    ]
    //   ],
    //   bronze: [
    //       {id: 2,
    //           name: 'McMaster ECE',
    //           websiteLink: 'https://www.eng.mcmaster.ca/ece',
    //           img: ecelogo
    //       },
    //       {id: 2,
    //         name: 'McMaster Materials Eng',
    //         websiteLink: 'https://www.eng.mcmaster.ca/materials',
    //         img: materialsLogo
    //     }
    // ]
  }
  }

  render() {
    return (
      <div className="sponsor-explorer-container">

           <h1 className="sponsor-type-heading"><span>Official EV Lubricants Partner</span></h1>


          <div className="sponsor-container lubrication">
             {this.state.lubrication.map(sponsor => (
                  <>
          <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
          <img className="sponsors-logo" src={sponsor.img} alt="lubrication-sponsor"/>
          <div className="sponsors-text-container">
          <h1>{sponsor.name}</h1>
          <h2 className="sponsor-text-description">
          {sponsor.description}
          </h2>
          </div>
          <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div>
          <div className="sponsor-flag" style={{background:sponsor.color}}>
          <h1 className="sponsor-flag-text">Visit
          </h1></div>
          </a>
          </>
          ))}
          </div>

        <h1 className="sponsor-type-heading"><span>Gold Sponsors</span></h1>


          <div className="sponsor-container gold">
             {this.state.gold.map(sponsor => (
                  <>
          <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
          <img className="sponsors-logo" src={sponsor.img} alt="gold-sponsor"/>
          <div className="sponsors-text-container">
          <h1>{sponsor.name}</h1>
          <h2 className="sponsor-text-description">
          {sponsor.description}
          </h2>
          </div>
          <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div>
          <div className="sponsor-flag" style={{background:sponsor.color}}>
          <h1 className="sponsor-flag-text">Visit
          </h1></div>
          </a>
          </>
          ))}
          </div>

            <h1 className="sponsor-type-heading"><span>Silver Sponsors</span></h1>
            <div className="sponsor-container silver">
            {this.state.silver.map(sponsor => (
                  <>
                  <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
            <img className="sponsors-logo" src={sponsor.img} alt="silver-sponsor"/>
            <div className="sponsors-text-container">
            <h1>{sponsor.name}</h1>
            <h2 className="sponsor-text-description">
            {sponsor.description}
            </h2>
            </div>
            <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div>
            <div className="sponsor-flag" style={{background:sponsor.color}}>
            <h1 className="sponsor-flag-text">Visit
                          </h1></div>
                  </a>  
                  </> 
              ))}
            </div>

            <h1 className="sponsor-type-heading"><span>Bronze Sponsors</span></h1>
            <div className="sponsor-container silver"> {/*temporary silver change back to bronze if there are real sponsors*/}

            {this.state.bronze.map(sponsor => (
                 <>
                  <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
                  <img className="sponsors-logo" src={sponsor.img} alt="bronze-sponsor"/>
                  <div className="sponsors-text-container">
                    <h1>{sponsor.name}</h1>
                    <h2 className="sponsor-text-description">
Kvaser supplies advanced CAN solutions to engineers designing and deploying systems in areas as wide ranging as trucks and buses, petrol-driven and electric cars, industrial automation, avionics, construction equipment, building automation, domestic appliances, marine, medical, military, railway, telecoms, textiles and more.                    </h2>
                  </div>   
                  <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div>
                  <div className="sponsor-flag" style={{background:sponsor.color}}>
                      <h1 className="sponsor-flag-text">Visit
                          </h1></div> 
                  </a>  
                  </> 
              ))}  
              {/* {this.state.bronze.map(sponsor => (
                 <>
                  <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
                  <img className="sponsors-logo" src={sponsor.img} alt="bronze-sponsor"/>
                  <div className="sponsors-text-container">
                    <h1>{sponsor.name}</h1>
                    <h2 className="sponsor-text-description">
                        {sponsor.description}
                    </h2>
                  </div>   
                  <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div>
                  <div className="sponsor-flag" style={{background:sponsor.color}}>
                      <h1 className="sponsor-flag-text">Visit
                          </h1></div> 
                  </a>  
                  </> 
              ))} */}
            </div>

            {/* <h1 className="sponsor-type-heading"><span>Bronze Sponsors</span></h1>
            <div className="sponsor-container silver">
              {this.state.bronze.map(sponsor => (
                  <>
                  <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
                  <img className="sponsors-logo" src={sponsor.img} alt="platinum-sponsor"/>
                  <div className="sponsors-text-container">
                    <h1>{sponsor.name}</h1>
                    <h2 className="sponsor-text-description">
                        {sponsor.description}
                    </h2>
                  </div>   
                  /!* <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div> *!/
                  <div className="sponsor-flag" style={{background:sponsor.color}}>
                      <h1 className="sponsor-flag-text">Visit
                          </h1></div> 
                  </a>  
                  </> 
              ))}
            </div>

            <h1 className="sponsor-type-heading"><span>General Sponsors</span></h1>
            <div className="sponsor-container silver">
              {this.state.general.map(sponsor => (
                  <>
                  <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
                  <img className="sponsors-logo" src={sponsor.img} alt="platinum-sponsor"/>
                  <div className="sponsors-text-container">
                    <h1>{sponsor.name}</h1>
                    <h2 className="sponsor-text-description">
                        {sponsor.description}
                    </h2>
                  </div>   
                  /!* <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div> *!/
                  <div className="sponsor-flag" style={{background:sponsor.color}}>
                      <h1 className="sponsor-flag-text">Visit
                          </h1></div> 
                  </a>  
                  </> 
              ))}
            </div> */}

      </div>
    )
  }
}

export default SponsorExplorer
