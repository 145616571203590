import React, { Component } from 'react';

import './Positions.css';

export class Positions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            positionsListed: 'all',
            positions: [
                {
                    type: ['mechanical'],
                    name: 'Mechanical Engineering',
                    description: [
                        {
                            head: 'About the Sub-Team:',
                            body:
                                'The Mechanical Engineering division of MAC Formula Electric is looking for future mechanical designers to help our growing team.',
                        },
                        {
                            head: 'About the Role(s):',
                            body:
                                'You will be responsible for aiding in the design process by creating CAD designs and drawings, documenting test findings, and summarizing results across the various mechanical sub-teams. You will have the opportunity to work alongside out team members and leads in producing drivetrain, suspension, chassis, and brake components. ',
                        },
                        {
                            head: 'Your Background:',
                            body:
                                ' A Strong Candidate Will Have: \n \n - CAD Experience (preferably Solidworks) \n \n - Experience with FEA software (ANSYS, Solidworks Simulation, Star-ccm+, etc…)  \n \n - Followed through on personal or academic engineering projects  \n \n - Interest in EVs or Motorsport \n \n - Desire to tackle engineering problems on their free time!',
                        },
                    ],
                    openDate: 'January 23, 2024 at 12:00 AM EST',
                    closeDate: 'January 26, 2024 at 11:59 PM EST',
                    applyLink:
                        'https://forms.microsoft.com/r/cJ12A7fj20',
                },
                // {
                //     type: ['mechanical'],
                //     name: 'Tractive Systems',
                //     description: [
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 '\nAccumulator \n' +
                //                 '\n' +
                //                 'Battery – Mechanical/Manufacturing \n' +
                //                 '\n' +
                //                 'The Accumulator sub-team is seeking a new mechanical member who will support our current mechanical group in manufacturing the battery’s structure. \n' +
                //                 '\n' +
                //                 'Recommended/Preferred Skills: \n' +
                //                 '\n' +
                //                 '   - Knowledge of reading and creating drawings using GD&T while following template formats \n' +
                //                 '\n' +
                //                 '   - Experience with basic hand, power, and machining tools \n' +
                //                 '\n' +
                //                 '   - General knowledge of battery structures and li-ion batteries is a plus \n' +
                //                 '\n' +
                //                 '   - Experience with Solidworks in any context is a plus \n' +
                //                 '\n' +
                //                 ' ',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 '\nBattery – Electrical \n' +
                //                 '\n' +
                //                 'The Accumulator sub-team is seeking a new electrical member to support the HV assembly of our battery pack. \n' +
                //                 '\n' +
                //                 'Recommended/Preferred Skills: \n' +
                //                 '\n' +
                //                 '   - General knowledge of HV devices such as batteries, inverters, power converters, and motors \n' +
                //                 '\n' +
                //                 '   - Experience with basic hand, power, and electrical tools.\n' +
                //                 '\n' +
                //                 '   - Experience with proper soldering, crimping, and wiring \n' +
                //                 '\n' +
                //                 '   - Experience working with and creating electrical test setups \n' +
                //                 '\n' +
                //                 '   - Knowledge of reading and creating wiring harness diagrams a huge plus \n' +
                //                 '\n' +
                //                 ' ',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 '\nMotor Drive \n' +
                //                 '\n' +
                //                 'Power Electronics – Cooling \n' +
                //                 '\n' +
                //                 'The Motor Drive sub-team is seeking a new member (preferably mechanical) to support in the build and test of our new motor cooling jackets and cooling loop. \n' +
                //                 '\n' +
                //                 'Recommended/Preferred Skills: \n' +
                //                 '\n' +
                //                 '   - General knowledge about fluids and thermodynamics.\n' +
                //                 '\n' +
                //                 '   - Knowledge about use of radiators, tubes, fittings, etc. in thermofluidic circuits \n' +
                //                 '\n' +
                //                 '   - Knowledge and experience with Solidworks \n' +
                //                 '\n' +
                //                 '   - Experience with Ansys Fluids a huge plus \n' +
                //                 '\n' +
                //                 '   - Testing experience with hydraulics a huge plus ' +
                //                 '\n' +
                //                 ' ',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body: 'Power Electronics – Firmware \n' +
                //                 '\n' +
                //                 'The Motor Drive sub-team is seeking a new member who has experience with embedded software to assist in development of the communications manager for our new vehicle, MAC EV4. \n' +
                //                 '\n' +
                //                 'Recommended/Preferred Skills: \n' +
                //                 '\n' +
                //                 '   - Experience with embedded systems (C/C++) or microcontrollers \n' +
                //                 '\n' +
                //                 '   - Experience working with STM32 microcontrollers is a plus \n' +
                //                 '\n' +
                //                 '   - Experience with Git \n' +
                //                 '\n' +
                //                 '   - Knowledge of communication protocols and standards; knowledge of CAN is a plus \n' +
                //                 '\n' +
                //                 ' ',

                //         },
                //     ],
                //     openDate: 'January 16, 2023 at 7:00 PM EST',
                //     closeDate: 'January 22, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/HHzXqdyRT2',
                // },
                {
                    type: ['business'],
                    name: 'Business',
                    description: [
                        {
                            head: 'About the Sub-Team:',
                            body:
                                '\nThe business sub-team of MAC Formula Electric is seeking creative and passionate students who are ready to tackle unique challenges in a post-lockdown environment. The engineering activities of our team is made possible through the marketing of our brand and value propositions, sponsorship opportunities with public and private stakeholders, and effective management of our finances. These activities are undertaken by the business sub-team, who is also responsible for keeping internal operations going smoothly. \n' +
                                '\n' +
                                'As a member of this sub-team, you can expect to be exposed to numerous principles originating from the business or communications studies/media arts world. Major topics covered by this sub-team include but are not limited to, accounting, finance, marketing, stakeholder relations, graphic design, media production, and project management. You can also expect to work with stakeholders from the engineering sub-teams to prepare demonstration material and presentations that will be used at various events such as open houses, trade shows, and most importantly, competitions. ',
                        },
                        {
                            head: 'About the Role(s):',
                            body:
                                '\nMembers of the business sub-team can expect to be involved in the following activities: \n' +
                                '\n' +
                                'Branding and Marketing: Work with Team Principal on continuous improvement of team’s branding guidelines, develop marketing campaigns to elevate profile of our team, and collaborate with everyone on the sub-team to generate proposal for team apparel.  \n' +
                                '\n' +
                                'Social Media: Manage the team’s social media presence and develop social media schedules according to internal goals and sponsorship obligations.  \n' +
                                '\n' +
                                'Content Creation: Develop content for external marketing purposes or sponsorship outreach. This can be videos or graphics used on social media, or content in the sponsorship package. \n' +
                                '\n' +
                                'Sponsorship and Stakeholder Relations: Manage an active list of current sponsors, work with engineering sub-teams to identify targeted sponsorship requests, perform market research to find other applicable sponsors, and maintain accurate record of outreach. ',
                        },
                        {
                            head: 'Your Background:',
                            body:
                                '\nYou are a suitable candidate for the business sub-team if some or all the following qualifications apply to you: \n' +
                                '\n' +
                                'You are eager to learn new concepts, or to apply existing skills or knowledge to new environments \n' +
                                '\n' +
                                'You enjoy working with others or individually on assigned projects \n' +
                                '\n' +
                                'You are passionate about exploring and devising creative solutions to unique challenges \n' +
                                '\n' +
                                'Excellent verbal and written interpersonal skills \n' +
                                '\n' +
                                'You are a great match for the business sub-team if some or all the following qualifications also apply to you: \n' +
                                '\n' +
                                'You are enrolled in a business/commerce, communication studies, media arts, or a program with applicable or transferrable knowledge or skills \n' +
                                '\n' +
                                'Prior experience in accounting or finance through extracurricular activities or professional work \n' +
                                '\n' +
                                'Prior experience in graphic design using vector artwork tools such as Adobe Illustrator or Inkspace (include link to portfolio of work if possible) \n' +
                                '\n' +
                                'Prior experience with layout design software such as Adobe InDesign (include link to portfolio of work if possible) \n' +
                                '\n' +
                                'Prior experience with marketing or sponsorship through extracurricular activities or professional work \n' +
                                '\n' +
                                'Prior experience in content creation (include link to portfolio of work if possible) \n' +
                                '\n' +
                                'Passion for motorsport and/or Formula 1  ',
                        },
                    ],
                    openDate: 'January 23, 2024 at 12:00AM EST',
                    closeDate: 'January 26, 2024 at 11:59 PM EST',
                    applyLink:
                        'https://forms.microsoft.com/r/ayrWQnP7ii',
                },
                // {
                //     type: ['other'],
                //     name: 'Reinstatement',
                //     description: [
                //         {
                //             head: 'Description:',
                //             body:
                //                 'Please complete this form if you are applying for reinstatement to the team. Form submissions are open to past members of the team from the current season. If you have been away from the team for one season or longer, or you are applying to join the team for the first time, please do not use this form.  Your submission will not be accepted.',
                //         },
                //     ],
                //     openDate: 'January 16, 2023 at 7:00 PM EST',
                //     closeDate: 'January 22, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/YPVsay8kV0',
                // },





                // {
                //     type: ['mechanical'],
                //     name: 'Brakes and Tires',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Brakes and Pedal Tray Sub-Team involves undergoing the engineering design process to create a reliable and efficient braking system. In racing vehicles, brakes are one of the most critical systems that must perform at very high levels for sustained periods of time. This is why we must design and validate all aspects of the system, starting from the brake pedal all the way to the tires on the road. We are also responsible for the pedal tray design which includes the accelerator, the pedal adjustment mechanism, and much more.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'We are looking for students of all levels who can work collaboratively with teammates. Tasks may consist of research, CAD, engineering drawings, calculations, FEA, optimization, manufacturing, assembly, etc. Students will spend time investigating ways to improve our design from the past year. We plan on having a much higher degree of analysis complete for the transient behavior of all aspects of the system. Because of this, students can expect to have the opportunity to learn and comprehend the entirety of the physics and engineering behind race car braking systems.',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'The best candidates are students who are eager to learn and discover the engineering behind race car dynamics. Candidates should be willing to put in committed effort and be able to work well in a team setting.\n\nIn terms of technical skills, previous CAD and FEA experience is preferred but not required. Familiarity with software such as Excel, SolidWorks, and ANSYS Workbench is preferred. Additionally, hands-on workshop experience is also desired but not required. Students with lesser technical background are encouraged to apply whether they have the desired skills or not. Ultimately, we are searching for students who have a desire to learn!',
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 17, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/MQ6JcriEcT',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Manufacturing',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'Manufacturing sub-teams are responsible for building the composite (carbon fiber and Kevlar) and metallic (steel, aluminum, copper, etc.) components of the vehicle in a safe and efficient manner. We regularly follow technical documentation such as engineering drawings to carry out our work so that the parts we make can take the loads and forces they were initially designed for.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'We are seeking mostly lower-year students for a variety of manufacturing activities. However, if you are an upper year with welding or machining experience, we encourage you to apply. Students will generally be tasked with working hands on, in the shop, on a daily basis with composite and metallic material. Compared to other sub-teams, manufacturing sub-team members by far get to spend the most time in the shop. This requires strong time commitment and discipline from team members. Research and design tasks will also be assigned, but on a less frequent basis as design orientated sub-teams.',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'The most important qualities that you can bring to the team are your time-management skills, enthusiasm, and dedication. Technical work can only be taught effectively if you are able to consistently show up to learn and work, which leads to knowledge retention. With that being said, any experience in CAD, CAM, FEA, mechanical testing, welding, or any sort of machining/workshop experience is desired but not required.',
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 17, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/0NTsMbyk1D',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Suspension and Steering',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'Suspension and Steering is responsible for design, manufacturing, testing, and validation of the suspension and steering systems. Our goal is to maximize performance of the car by ensuring the tires stay in contact with the ground and the vehicle dynamics are optimal for the driver by adding adjustability to our parts to be able to tune the suspension. This year, for suspension we will be iterating on last year’s designs, which includes the uprights, bell cranks, control arms, and spring/damper. For steering we will be working on the steering rack, tie rods, and steering column. We will also be testing our designs, while ensuring that all suspension components are being built with safety in mind and meet all technical regulations.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'Members will be contributing to the design areas listed above. Upper year members will be expected to assist with running simulations, performing calculations, and obtaining test data to validate our designs. Lower-year students will be responsible for CAD work, preparing technical drawings, and assisting with manufacturing and assembly. However, team members are free to choose tasks that they feel they can contribute to and are of interest to them – we want people to learn as much as they can irrespective of their academic year and experience. The expectations above are only a rough outline, not a hard rule of what team members can or will be doing throughout the year.',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'We have lots of goals for this upcoming season. It requires members who are reliable, can work collaboratively with others, and complete tasks in a timely manner. Having previous experience with the skillsets below is an asset, but we are more than willing to teach new members the skills necessary for success. Above all, we are looking for people who are genuinely interested in our team, have a passion for the things we do, and who have a desire to learn.\n\n- Previous CAD and Drafting experience is preferred\n\n- Experience with Ansys or other simulation software is a plus but not mandatory\n\n- Knowledge of MATLAB or Python is also a plus but not mandatory'
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 17, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/w1Z0vBDZ7V',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Drivetrain',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'On the drivetrain sub-team, we are responsible for research, design, testing, and validation of the gearbox that will be connecting the motor to the wheels of the vehicle. We use engineering design principles and a variety of CAD and CAE tools to determine the correct design parameters of the gear box. Examples of such parameters include the gear ratio, gear geometry, tolerances, and choice of material.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'We are seeking a mix of upper- and lower-year students. Upper-year students may be expected, but are not limited to, creating assembly models, performing simulations, calculating stresses & failure modes, testing and validating components, and supporting manufacturing/assembly. Lower year students may be expected, but are not limited to, making drawings, modelling parts, generating reports, conducting literature review, and supporting the design and assembly process.',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 '- Understanding of basic mechanical engineering design theory & principles\n\n- 3D modelling & CAD experience\n\n- Knowledge of drafting best practices\n\n- Experience using simulation software is a bonus (i.e. SOLIDWORKS Simulation, Ansys)\n\n- Knowledge of MATLAB or Python is a plus\n\n- Strong communication skills and ability to collaborate effectively with other students to complete projects\n\n- Must be reliable and willing to contribute time to the team\n\n- Eagerness to learn and put effort into your work'
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 17, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.microsoft.com/r/qpFR5KiLDq',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Human Factors & Ergonomics',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The human factors and ergonomics sub-team is responsible for the design and validation of human-machine interfaces such as the steering wheel, seat, headrest and dashboard, as well as the justification of the driver’s position in the car. Our goal is to maximize the driver’s safety and comfort in the car, thus allowing them to fully focus on the drive. We use research, CAD tools, real world testing and driver feedback to drive our work. The ergonomics team collaborates with many other sub-teams to ensure human interaction is considered and safety rules are upheld.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'This role is open to both upper- and lower-level students. On this sub team, students from all levels collaborate to design and justify and optimize useful ergonomic features for the vehicle. Tasks may consist of research, CAD, engineering drawings, calculations, optimization, manufacturing, etc.',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'Members are expected to:\n\n- Have strong communication and collaboration skills\n- Be reliable and consistent with attending meetings and completing tasks in a timely manner\n- Be eager and willing to learn and explore\n\nPrevious CAD experience is helpful for this role but not required. Surface modeling and FEA experience are a plus. Interest in mechanisms, biomechanics, human behavior and statistics are a plus. '
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 17, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/JTEK5YN4ze',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Chassis',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'On the chassis sub-team, we develop the base or frame of the vehicle. We design a bespoke carbon fiber composite monocoque every year plus all the other added safety components to compete at sanctioned Formula SAE events. This Includes Roll hoops, Jack Bar, Firewall, Access hatches and all the materials testing to prove stiffness and strength equivalency.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'This sub-team is open to both upper- and lower-level students. All students are involved in both the designing and testing/adjusting of carbon fiber panels, firewall, roll hoops, and other car elements. Upper year students will have a focus on simulation and layup design theory while lower year students will help with CAD, Drawings, and manufacturing. The expectations above are only a rough outline, not a hard rule of what team members can or will be doing throughout the year.',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'Previous CAD and CAE experience is preferred but not required. There will be opportunities for members to learn throughout the year on the sub-team. Previous knowledge of racing cars and/or materials testing, and categorization will also be useful but not required. Members can expect to share tasks with other members, which will require them to collaborate well and demonstrate active listening. Members are expected to learn, work hard, as well as being reliable and accountable.'
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 17, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/pA0u78XA8f',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Aerodynamics',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Aerodynamics Sub-Team is responsible for the justification, design, analysis, manufacturing, and validation of all aerodynamic devices meant to alter the downforce and drag characteristics of the vehicle. The goal is to enhance the mechanical grip that the tires can provide under acceleration, deceleration, and cornering, while also minimizing drag for maximum energy efficiency. For the time being, devices include the nose cone, front wing, and rear wing. Analysis of chassis and suspension on downstream airflow and active drag reduction methods will also be explored in the coming years, as these are essential parts of maximizing EV energy efficiency.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'Unfortunately, public recruitment for the Aerodynamics Sub-Team will not take place for the development of MAC EV5. With the subteam restarting from scratch after a year of inactivity, we have hand selected involved and dedicated members from the development of MAC EV4 to form the sub-team. This will ensure that we build the sub-team on solid, reliable workflows which can then be followed and enhanced by external hires for MAC EV6.',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'Previous CAD, fluid mechanics, CFD, and CFRP manufacturing experience is desired but not required. Every year, internal meetings will include lessons about the fundamentals of racecar aerodynamics to more advanced concepts, ensuring every member of the sub-team is on the same page and capable of aiding the design, analysis, manufacturing, and validation portions of the build cycle. Members will be expected to work hard, ask questions, stay in touch with the Aerodynamics Design Lead about their tasks, attend meetings, and be positive examples of the team around campus.'
                //         },
                //     ],
                //     openings: 'None',
                //     applyLink:
                //         '',
                // },
                // {
                //     type: ['electrical'],
                //     name: 'LV/HV Electronics',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'We at MAC Formula Electric are looking for passionate students that want to get involved in hardware engineering and design. We are looking for students that have a passion for learning, exploring, and taking on projects from start to finish.\n\nThe LV/HV electronics sub-team is tasked with designing, manufacturing, and testing the electrical system for MAC Formula Electric. On this team, you can expect to be exposed to a vast array of electronic systems including vehicle communication, safety circuitry, high voltage control circuitry, power supply circuits, and much more. Our team works closely with the mechanical, software, and powertrain sub-teams to create a cohesive design for each competition season.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'On the LV/HV electronics sub-team, you will be involved in helping with start to finish manufacturing of custom designed PCB boards as well as testing and validating electrical sub-systems through SPICE simulations. You will gain experience using an industry standard PCB design software, Altium Designer, as well as have the opportunity to be hands-on, using laboratory equipment such as oscilloscopes, DC power supplies, DC loads, and multimeters to help test the electrical architecture.',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'Key Qualifications:\n- Eagerness to learn\n- Excellent verbal and written interpersonal skills\n- Excellent collaboration skills\n- Problem solving skills\n- Ability to work in fast paced environment\n\nKey Qualifications:\n- Prior internship(s), personal project experience, TA/work in related fields\n- Strong electrical engineering fundamentals\n- Design experience with CAD software such as Altium or Eagle \n- Proficiency in reading schematics\n- Experience using power supplies, multimeters, oscilloscopes, etc'
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 18, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/qcPCC83vMR',
                // },
                // {
                //     type: ['electrical'],
                //     name: 'Wire Harness',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'We at MAC Formula Electric are looking for passionate students that want to get involved in hardware engineering and design. We are looking for students that have a passion for learning, exploring, and taking on projects from start to finish.\n\nThe wire harness sub-team is tasked with designing, manufacturing, and testing the entire vehicle wire harness. You can expect to be exposed to industry level wire harness design and manufacturing techniques as well as working closely with mechanical and powertrain sub-teams to integrate the harness in the vehicle.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'On the wire harness sub-team, you will be involved in helping connect the entire electrical system around the car. Your tasks will include designing and optimizing the routing of the vehicles wiring harness using Solidworks prior to manufacturing and installation. You will perform tests on the vehicle harness to confirm continuity from the front to the back of the car. You will use Altium and Excel to organize over 120 circuits while creating connector tables for the over 20 connectors used in the vehicle. You will work closely with the LV/HV electronics and the powertrain sub-teams to integrate the wire harness efficiently.',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'Key Qualifications:\n- Eagerness to learn\n- Excellent verbal and written interpersonal skills\n- Excellent collaboration skills\n- Problem solving skills\n- Ability to work in fast paced environment\n\nKey Qualifications:\n- Prior internship(s), personal project experience, TA/work in related fields\n- Strong electrical engineering fundamentals\n- Design experience with CAD software such as Solidworks, Autodesk Inventor, Altium, and Eagle\n- Proficiency in Excel\n- Proficiency in reading schematics\n- Experience using power supplies, multimeters, oscilloscopes'
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 18, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/d7BhBf50Dq',
                // },
                // {
                //     type: ['software'],
                //     name: 'Software & Controls',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'We at MAC Formula Electric are looking for passionate students that want to get involved in the design, development, debugging, integration, and documentation of embedded software. We are looking for students that have a passion for learning, exploring, and taking on projects from start to finish.\n\nThe software and controls sub-team is tasked with designing, developing, testing, and integrating software on our STM32 embedded platform to monitor and control the mechanical and electrical subsystems of the vehicle.\n\nOn this team, you can expect to be exposed to a variety of industry standard hardware, software, and skills such as C/C++ programming, control design in MATLAB/Simulink, Python scripting, STM32 MCUs, hardware debuggers, CubeMX IDE, oscilloscopes, signal generators, breadboard prototyping, and in-vehicle testing. Our team works closely with the electrical sub-team to flesh out hardware requirements and vehicle integration strategies.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'On the software & controls sub-team, you will be involved in the board support package (BSP) bring up of all our ECUs.  This includes full benchtop and in-vehicle testing of all pre-existing MAC EV code sets and Simulink models for critical vehicle functionality such as the bootup/shutdown sequence, communication stack manager. Additionally, you will be working to further develop the existing vehicle control system to enable functions such as torque vectoring, traction control, and regen braking. You will also be integrating the vehicle controls system onto our embedded hardware, ensuring all ECUs on the vehicle can communicate to and act upon the commands from the control system. You will be expected to thoroughly document your research, progress, pit falls, testing methodology, integration efforts, through design documents and team planners. Throughout your time, you will learn the ins and outs of developing, testing, and integrating embedded software on an electric vehicle.',
                //         },
                //         {
                //             head: 'Required Qualifications',
                //             body:
                //                 '- Eagerness to learn new concepts quickly and independently\n- Ability to effectively communicate complex concepts to both senior and junior members\n- Will power to struggle for days on complex problems and maintain a positive attitude and mindset\n- Natural curiosity to dig deep into completely foreign problems and concepts\n- Excellent verbal and written interpersonal skills'
                //         },
                //         {
                //             head: 'Nice to Haves',
                //             body:
                //                 '- Prior internship or co-op experience designing, developing, testing, debugging, and pushing production-level code in C/C++\n- Experience with Git\n- Experience with control design in MATLAB/Simulink\n- Knowledge of communication protocols and standards; knowledge of CAN is a plus\n- Strong software engineering fundamentals, such as OOD, OOP, etc.\n- Experience with creating and using dynamic models of mechanical, electrical, or thermal systems, in Matlab/Simulink or similar environments\n- Experience in fundamental vehicle dynamics theory, specifically models to be used within control algorithm\n- Ability to read schematics and debug hardware-related issues\n- Personal projects that show you can go from an idea to a fully completed task on your own'
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 17, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/Xibws1Tg4n',
                // },
                // {
                //     type: ['powertrain'],
                //     name: 'Accumulator',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Powertrain team is focused on developing, designing, integrating and testing the electrical powertrain components into MAC EV5. You can expect to interface with critical components that produce the car\'s motion such as the electric motors, inverters, and battery. Our value comes from solving problems using highly interdisciplinary approaches with an emphasis on balanced solutions. As a result, we work with all sub-teams and aim to build cohesive and competitive designs for each competition season.\n\nThe Accumulator sub-team is responsible for the design, testing, and physical integration of the accumulator assembly: the complete package of energy storage, and mechanical and electrical regulatory components. The great challenge in designing an accumulator, is in considering how the thermal and electrical performance of the selected energy storage modules can meet both our performance goals and the official rules requirements, while packaging and mounting it to be space and weight efficient, and easy to service.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'The Accumulator sub-team is looking to add members who can aid us in developing solutions for our mechanical and thermal problems. As a prospective member you can look forward to:\n- Using mechanics and statics principles in aiding the design of housings/enclosures\n- The chance to exercise your thermodynamic and heat transfer knowledge to evaluate the cooling needs of our HV components by validating your decisions with thermal simulations and physical testing\n- Working with the full car assembly in SolidWorks; helping design the packaging and mounting solutions to be ergonomic and space efficient\n\nOn the electrical side, you will be involved in supporting current electrical members in:\nValidating and testing current circuit designs to ensure that our designs are safe and rules compliant\nTesting cells and characterizing our battery pack in order to improve thermal models, which feeds back into the optimization of our designs\nOptimizing packaging of harnesses and PCBs for ease of use and efficiency',
                //         },
                //         {
                //             head: 'Background and Qualifications:',
                //             body:
                //                 'The ideal senior mechanical engineer has plenty of good technical background, CAD experience, and some experience conducting simulations in either in Ansys, MATLAB, StarCCM+, etc. The ideal candidate also enjoys working with their hands.\n\nThe ideal senior electrical engineer has experience working with HV components (including HV battery packs, power converters, busbars, etc.). The ideal candidate has also worked with PCB laying software such as Altium or OrCAD and has used SPICE simulators like LTspice.\n\nFor lower years, we care about students who are passionate about their technical interests. Someone who can take direction and can also contribute in meaningful non-technical ways by bringing their unique talent to the team.'
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 18, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://docs.google.com/document/d/1aoE2do4M0SJxdt4V2BZudBp9q85uD9mWUj1t95mbC9k/edit?usp=sharing',
                // },
                // {
                //     type: ['powertrain'],
                //     name: 'Motor Drive',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Powertrain team is focused on developing, designing, integrating and testing the electrical powertrain components into MAC EV5. You can expect to interface with critical components that produce the car\'s motion such as the electric motors, inverters, and battery. Our value comes from solving problems using highly interdisciplinary approaches with an emphasis on balanced solutions. As a result, we work with all sub-teams and aim to build cohesive and competitive designs for each competition season.\n\nThe Motor Drive sub-team is responsible for the testing and integration of the motor, motor drive and inverter assembly with its connected sub-assemblies (accumulator and drivetrain). The lack of strict rules for this subsystem gives this team a lot of freedom in the full optimization of the subsystem by utilizing data and feedback to iteratively improve the performance of the subsystem thereby improving the performance of the vehicle.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'The Motor Drive sub-team is looking to add members who can aid us in developing solutions for our mechanical and thermal problems. As a prospective member you can look forward to:\n- The further optimization of inverter packaging by creating space efficient solutions and using multi-material methods to further reduce weight of the system\n- Component choice analysis and its impacts on various aspects of cooling performance by testing and validating component performance\n- Working with the drivetrain team to integrate motor accessories into upright design\n\nOn the electrical side, you will be involved in supporting current electrical members in:\n- Validating and testing current circuit designs to ensure that our designs are safe and rules compliant\n- Modelling and laying out efficient wiring of the inverter and motor drive boards using SolidWorks ECAD\n- Utilizing feedback from motor drive cycles to resolve issues and analyze how to further improve motor drive performance',
                //         },
                //         {
                //             head: 'Background and Qualifications:',
                //             body:
                //                 'The ideal senior mechanical engineer has plenty of good technical background, CAD experience, and experience conducting simulations in Ansys, MATLAB, StarCCM+, etc. The ideal candidate also enjoys working with their hands.\n\nThe ideal senior electrical engineer has experience working with HV components (including HV battery packs, power converters, busbars, etc.). The ideal candidate has also worked with PCB laying software such as Altium or OrCAD and has used SPICE simulators like LTspice. Experience interfacing with firmware and controls engineers a huge plus.\n\nFor lower years, we care about students who are passionate about their technical interests. Someone who can take direction and can also contribute in meaningful non-technical ways by bringing your unique talent to the team.'
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 18, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://docs.google.com/document/d/1aoE2do4M0SJxdt4V2BZudBp9q85uD9mWUj1t95mbC9k/edit?usp=sharing',
                // },
                // {
                //     type: ['business'],
                //     name: 'Business',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The business sub-team of MAC Formula Electric is seeking creative and passionate students who are ready to tackle unique challenges in a post-lockdown environment. The engineering activities of our team is made possible through the marketing of our brand and value propositions, sponsorship opportunities with public and private stakeholders, and effective management of our finances. These activities are undertaken by the business sub-team, who is also responsible for keeping internal operations going smoothly.\n\nAs a member of this sub-team, you can expect to be exposed to numerous principles originating from the business or communications studies/media arts world. Major topics covered by this sub-team include but are not limited to, accounting, finance, marketing, stakeholder relations, graphic design, media production, and project management. You can also expect to work with stakeholders from the engineering sub-teams to prepare demonstration material and presentations that will be used at various events such as open houses, trade shows, and most importantly, competitions.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'Members of the business sub-team can expect to be involved in the following activities:\n- Branding and Marketing: Work with the Team Principal on continuous improvement of team’s branding guidelines and develop marketing campaigns to elevate our team profile\n- Social Media: Manage the team’s social media presence and develop social media schedules according to internal goals and sponsorship obligations\n- Content Creation: Develop content for external marketing purposes or sponsorship outreach. This can be videos or graphics used on social media, or content in the sponsorship package\n- Sponsorship and Stakeholder Relations: Manage an active list of current sponsors, work with engineering sub-teams to identify targeted sponsorship requests, perform market research to find other applicable sponsors, and maintain accurate record of outreach',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'You are a suitable candidate for the business sub-team if some or all the following qualifications apply to you:\n- You are eager to learn new concepts, or to apply existing skills or knowledge to new environments\n- You enjoy working with others or individually on assigned projects\n- You are passionate about exploring and devising creative solutions to unique challenges\n- Excellent verbal and written interpersonal skills\n\nYou are a great match for the business sub-team if some or all the following qualifications also apply to you:\n- You are enrolled in a business/commerce, communication studies, media arts, or a program with applicable or transferrable knowledge or skills\n- Prior experience in accounting or finance through extracurricular activities or professional work\n- Prior experience in graphic design using vector artwork tools such as Adobe Illustrator or Inkspace (include link to portfolio of work if possible)\n- Prior experience with layout design software such as Adobe InDesign (include link to portfolio of work if possible)\n- Prior experience with marketing or sponsorship through extracurricular activities or professional work\n- Prior experience in content creation (include link to portfolio of work if possible)\n- Passion for motorsport and/or Formula 1'
                //         },
                //     ],
                //     openDate: 'September 13, 2023 at 11:59 PM EST',
                //     closeDate: 'September 17, 2023 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.microsoft.com/r/FVJhGKxmDS',
                // },
            ],
        };
    }

    updatePositions = (newPositions) => {
        this.setState({ positionsListed: newPositions });
    };

    updatePositionsVisible = (newPositions) => {
        this.setState({ positionsListed: newPositions });
    };

    render() {
        const visiblePosition = this.state.positions.filter(
            (position) =>
                position.type.includes(this.state.positionsListed) ||
                this.state.positionsListed === 'all'
        );

        const positions = (
            <div className="positions-section-container">
                <div className="positions-container">
                    {visiblePosition.map((position) => (
                        <div
                            className={`position-container ${
                                position.applyLink === '#' ? 'closed' : 'open'
                            }`}
                        >
                            <div
                                className="position"
                                onClick={this.props.updatePositionPage.bind(
                                    this,
                                    position
                                )}
                            >
                                <div className="position-title-container">
                                    <h1 className="position-title">
                                        {position.name}
                                    </h1>
                                </div>
                                <div className="position-openings-container">
                                    <h2 className="position-opening-title">
                                        Opens:{' '}
                                    </h2>
                                    <h1 className="position-openings">
                                        {position.openDate}
                                    </h1>
                                </div>
                                <div className="position-openings-container">
                                    <h2 className="position-opening-title">
                                        Closes:{' '}
                                    </h2>
                                    <h1 className="position-openings">
                                        {position.closeDate}
                                    </h1>
                                </div>
                                <div className="position-description-container">
                                    <p className="position-description">
                                        {' '}
                                        {position.description[0].body.length >
                                        300
                                            ? position.description[0].body
                                                  .split('')
                                                  .slice(0, 300)
                                                  .join('') + '...'
                                            : position.description[0].body}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );

        return (
            <div>
                {/* <PositionSelector updatePosition={this.updatePositions} positions={this.state.positionsListed}/> */}
                {positions}
            </div>
        );
    }
}

export default Positions;
