import React, {
  Component
} from 'react'

import './TeamMembers.css'

import JoshuaPic from './../../../img/team-members/joshua_dorland.jpg';
import SamuelPic from './../../../img/team-members/samuel_parent.jpg';
import LesterPic from './../../../img/team-members/lester_malecon.jpg';
import MatthewPic from './../../../img/team-members/matthew_cheung.jpg';
import CarterPic from './../../../img/team-members/carter_fenton.jpg';
import AndrePic from './../../../img/team-members/andre_azevedo.jpg';
import AsadPic from './../../../img/team-members/asad_soomro.jpg';
import AriannaPic from './../../../img/team-members/arianna_ramotar.jpg';
import LinPic from './../../../img/team-members/lin_fu.jpg';
import HimanshuPic from './../../../img/team-members/himanshu_singh.jpg';
import LucasPic from './../../../img/team-members/lucas_haber.jpg';
import ShaanPic from './../../../img/team-members/shaan_suthar.jpg';
import SamPic from './../../../img/team-members/sam_schincariol.jpg';
import SimonPic from './../../../img/team-members/simon_armbruster.jpg';
import JessicaPic from './../../../img/team-members/jessica_mcguire.jpg';
import OwenPic from './../../../img/team-members/owen_spira.jpg';
import SantiagoPic from './../../../img/team-members/santiago_barrera-thirlwall.jpg';
import IvanPic from './../../../img/team-members/ivan_lange.jpg';
import RidaPic from './../../../img/team-members/rida_safeer.jpg';
import JordanPic from './../../../img/team-logos/black-background-logo.png';
import IoannisPic from './../../../img/team-members/ioannis_papaspyridis.jpg';
import SophiaPic from './../../../img/team-members/sophia_mokhtari.jpg';

export class TeamMembers extends Component {
  constructor() {
    super()
    this.state = {
      members: [{
          name: 'Arianna Ramotar',
          photo: AriannaPic,
          position: 'Team Principal',
          linkedin: 'https://www.linkedin.com/in/ariannaramotar/'
        },
        {
          name: 'Joshua Dorland',
          photo: JoshuaPic,
          position: 'Electrical Technical Director',
          linkedin: 'https://www.linkedin.com/in/joshdorland/'
        },
        {
          name: 'Carter Fenton',
          photo: CarterPic,
          position: 'Mechanical Technical Director',
          linkedin: 'https://www.linkedin.com/in/carter-g-fenton/'
        },
        {
          name: 'Samuel Parent',
          photo: SamuelPic,
          position: 'Software Technical Director',
          linkedin: 'https://www.linkedin.com/in/samparent/'
        },
        {
          name: 'Lin Fu',
          photo: LinPic,
          position: 'Software Technical Director',
          linkedin: "https://www.linkedin.com/in/lin-fu101/"
        },
        {
          name: 'Lester Malecon',
          photo: LesterPic,
          position: 'Powertrain Technical Director',
          linkedin: "https://www.linkedin.com/in/lmalecon/"
        },
        {
          name: 'Himanshu Singh',
          photo: HimanshuPic,
          position: 'HV/LV Electronics Lead',
          linkedin: "https://www.linkedin.com/in/himanshu-singh-99470b207/"
        },
        {
          name: 'Lucas Haber',
          photo: LucasPic,
          position: 'Wire Harness Lead',
          linkedin: "https://www.linkedin.com/in/lucas--haber/"
        },
        {
          name: 'Shaan Suthar',
          photo: ShaanPic,
          position: 'Suspension & Steering Lead',
          linkedin: "https://www.linkedin.com/in/shaan-suthar/"
        },
        {
          name: 'Sam Schincariol',
          photo: SamPic,
          position: 'Manufacturing (Machining) Lead',
          linkedin: "https://www.linkedin.com/in/sam-schincariol-17445422a/"
        },
        {
          name: 'Simon Armbruster',
          photo: SimonPic,
          position: 'Manufacturing (Composites) Lead',
          linkedin: "https://www.linkedin.com/in/simon-armbruster-a421b2261/"
        },
        {
          name: 'Jessica McGuire',
          photo: JessicaPic,
          position: 'Brakes & Tires Lead',
          linkedin: "https://www.linkedin.com/in/jessica-mcguire-pm/"
        },
        {
          name: 'Andre Azevedo',
          photo: AndrePic,
          position: 'Chassis Lead',
          linkedin: "https://www.linkedin.com/in/andrebaa/"
        },
        {
          name: 'Owen Spira',
          photo: OwenPic,
          position: 'Human Factors & Ergonomics Lead',
          linkedin: "https://www.linkedin.com/in/owen-spira/"
        },
        {
          name: 'Asad Soomro',
          photo: AsadPic,
          position: 'Aerodynamics Lead',
          linkedin: "https://www.linkedin.com/in/asad-soomro/"
        },
        {
          name: 'Santiago Barrera-Thirlwall',
          photo: SantiagoPic,
          position: 'Drivetrain Lead',
          linkedin: "https://www.linkedin.com/in/santiago-bt/"
        },
        {
          name: 'Ivan Lange',
          photo: IvanPic,
          position: 'Software & Controls Lead',
          linkedin: "https://www.linkedin.com/in/ivanlange/"
        },
        {
          name: 'Rida Safeer',
          photo: RidaPic,
          position: 'Software & Controls Lead',
          linkedin: "https://www.linkedin.com/in/rida-safeer/"
        },
        {
          name: 'Hady Ibrahim',
          photo: JordanPic,
          position: 'Software & Controls Lead', 
          linkedin: "https://www.linkedin.com/in/hady-ibrahim/"
        },
        // {
        //   name: 'Matthew Cheung',
        //   photo: MatthewPic,
        //   position: 'Accumulator Lead',
        //   linkedin: "https://www.linkedin.com/in/matthewncheung/"
        // },
        {
          name: 'Kartikeya Babhuta',
          photo: JordanPic,
          position: 'Accumulator Electrical Lead',
          linkedin: "https://www.linkedin.com/in/kbabhuta/"
        },
        {
          name: 'James Ensley',
          photo: JordanPic,
          position: 'Accumulator Mechanical Lead',
          linkedin: "https://www.linkedin.com/in/james-ensley/"
        },
        {
          name: 'Jordan Van Dam',
          photo: JordanPic,
          position: 'Motor Drive Lead',
          linkedin: "https://www.linkedin.com/in/jordanvandam/"
        },
        {
          name: 'Ioannis Papaspyridis',
          photo: IoannisPic,
          position: 'Motor Drive Lead',
          linkedin: "https://www.linkedin.com/in/ipapaspyridis/"
        },
        {
          name: 'Sophia Mokhtari',
          photo: SophiaPic,
          position: 'Management Personal (Powertrain) Lead',
          linkedin: "https://www.linkedin.com/in/sophiamokhtari/"
        }
      ]
    }
  }


  render() {
    return ( 
      <div className = "" >
      <h1 className = "team-showcase-text" > TEAM LEADERSHIP </h1> 
      <div className = "team-members-container" >
      {
        this.state.members.map(member => ( 
          <div className = "team-member-container" >
          <div className = "team-member-image-container" >
          <img className = "team-member-image" src = {member.photo} alt = "" />
          </div> 
          <div className = "team-member-text-container" >
          <h1 className = "team-member-name" > {member.name} </h1> 
          <h2 className = "team-member-position" > {member.position} </h2> 
          <a href = {member.linkedin} style = {{textDecoration: 'none'}} target = "_blank" rel = "noopener noreferrer" >
          <div className = "team-member-linkedin-button" >
          <h1 className = "team-member-linkedin-name" > in </h1> 
          </div> 
          </a> 
          </div> 
          </div>
        ))
      } 
      </div> 
      </div>
    )
  }
}

export default TeamMembers;
