import React, { Component } from 'react'
import Header from '../header/Header';
import ShowcaseLink from '../showcaselink/ShowcaseLink';

import './SponsorsPage.css'

import hybridInHatch from './../../img/hatch/hybrid-stand.jpg';
import sidePodshot from './../../img/parts/hybrid-side-pod.png';
import SponsorExplorer from './sponsorexplorer/SponsorExplorer';
import Footer from '../footer/Footer';

import sponsorShipPackage from './../../MACFE_Sponsorship_package.pdf';

export class SponsorsPage extends Component {

componentDidMount() {
  window.scrollTo(0, 0)
  setTimeout(function() {
    document.getElementById("sponsorship-top-showcase").style.opacity = 1;
    document.getElementById("sponsorship-top-showcase-image").style.right = "50px";

    if(window.innerWidth>1400) {
      document.getElementById("sponsorship-top-showcase-text").style.top = "300px";
    } else {
      document.getElementById("sponsorship-top-showcase-text").style.top = "20vw";
    }

  },500);
}

  render() {
    return (
      <div>
        <Header />
        <div id="sponsorship-top-showcase">
          <img id="sponsorship-top-showcase-image" src={hybridInHatch} alt=""></img>
          <div className="sponsorship-showcase-gradient"/>
          <div id="sponsorship-top-showcase-text">
            <h1>Sponsorship Opportunities</h1>
            <h2>MAC Formula Electric's technical and competitive success is made possible through the support of organizations and individuals with shared values. We foster an environment for our members that develops them into well-rounded members of your organization as co-op students or future employees.</h2>
            <h2><a href={"https://issuu.com/macformularacing/docs/macfe_-_sponsorship_package_2023_-_2024"} target="_blank">View our Sponsorship Package</a></h2>
            <h2>If you would like to discuss sponsorship opportunities directly with a member of our team, please reach out via <a href={"mailto:formula@mcmaster.ca?subject=2022-2023 Sponsorship Inquiry"}>formula@mcmaster.ca</a></h2>
          </div>
        </div>
        <SponsorExplorer />
        <Footer />
      </div>
    )
  }
}

export default SponsorsPage
